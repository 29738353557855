export default {
  SALES_ORDERS_LIST: "Liste des commandes",
  ADD_SALES_ORDER: "Ajouter une commande",
  ADD_SALES_ORDER_ITEM: "Ajouter une ligne",
  SALES_ORDER_ADDED: "Commande ajoutée",
  OWNER: "Propriétaire",
  EDIT_SALES_ORDER: "Modifier une commande",
  SALES_ORDER_UPDATED: "Commande mise à jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_SALES_ORDER_ITEM: "Supprimer la ligne",
  UPDATE_SALES_ORDER_ITEM: "Modifier la ligne",
  SALES_ORDER_ITEM_DELETED: "Ligne supprimée",
  SALES_ORDER_ITEM_ADDED: "Ligne ajoutée",
  SALES_ORDER_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  SALES_ORDER_SENT: "Commande envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir l'organisation",
  SELECT_SUPPLIERS: "Choisir le fournisseur",
  DELETE_THIS_SALES_ORDER: "Supprimer cette commande?",
  SALES_ORDER_DELETED: "Commande supprimée",
  CANCEL_SALES_ORDER_QUESTION: "Annuler la commande?",
  SALES_ORDER_CANCELED: "Commande annulée",
  RECIPIENT: "Destinataire",
  ISSUER: "Émetteur",
  VALIDATE: "Valider",
  SALES_ORDER_VALIDATED: "Commande validée",
  GENERATE_INVOICE: "Créer la facture",
  INVOICE_GENERATED: "Facture crée",
  VIEW_INVOICE: "Voir la facture",
  ADD_DELIVERY: "Ajouter une livraison",
  DELIVERIES_STATE: "État de livraison",
  DELIVERIES_STATE_ITEM: "Produit",
  DELIVERIES_STATE_ORDERED_QUANTITY: "Quantité commandée",
  DELIVERIES_STATE_DELIVERED_QUANTITY: "Quantité livrée",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Quantité restant",
  DELIVERIES_STATE_REMAINING_QUANTITY: "Quantité restant",
  NOT_INVOICED: "A facturer",
  NOT_DELIVERED: "A livrer",
  DELIVERED: "Livrée",
  SALES_INVOICES: "Factures vente",
  ADD_INVOICE: "Ajouter une facture",
  INVOICING_TYPE: "Type de facturation",
  INVOICING_TYPE_COMPLETE: "Complète",
  INVOICING_TYPE_AMOUNT: "Montant",
  INVOICING_TYPE_PRODUCT: "Produit",
  INVOICING_STATUS_PENDING: "Non Facturée",
  INVOICING_STATUS_PARTIALLY_INVOICED: "Partiellement facturée",
  INVOICING_STATUS_INVOICED: "Facturée",
  DELIVERY_STATUS_PENDING: "Non Livrée",
  DELIVERY_STATUS_PARTIALLY_DELIVERED: "Partiellement livrée",
  DELIVERY_STATUS_DELIVERED: "Livrée",
  INVOICING_STATUS: "Status facturation",
  ORDER_STATUS_DRAFT: "Brouillon",
  ORDER_STATUS_CANCELED: "Annulée",
  ORDER_STATUS_VALIDATED: "Validée",
  ORDER_STATUS_COMPLETED: "Complétée",
  DELIVERY_STATUS: "Status livraison",
  INVOICE_TYPE: "Type de facture",
  INVOICE_TYPE_DOWNPAYMENT: "Facture d'acompte",
  INVOICE_TYPE_STANDARD: "Facture standard",
  SALES_ORDER_INVOICE_ADDED: "Facture ajoutée",
  VIEW_SALES_ORDER: "Commande achat",

  CREATED_AT: "Créée le",
};
