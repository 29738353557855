<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <!--<div class="elite-content_body_inner_header_left">
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.10713 8.52343C4.39325 8.52343 3.8125 9.10427 3.8125 9.81803C3.8125 10.5319 4.39325 11.1127 5.10713 11.1127C5.82089 11.1127 6.40176 10.5319 6.40176 9.81803C6.40176 9.10427 5.82089 8.5234 5.10713 8.5234V8.52343ZM5.10713 10.1059C4.94844 10.1059 4.81927 9.97682 4.81927 9.81803C4.81927 9.65933 4.94844 9.53026 5.10713 9.53026C5.26583 9.53026 5.3949 9.65933 5.3949 9.81803C5.3949 9.97682 5.26583 10.1059 5.10713 10.1059ZM9.37044 8.52343C8.65655 8.52343 8.0758 9.10427 8.0758 9.81803C8.0758 10.5319 8.65655 11.1127 9.37044 11.1127C10.0843 11.1127 10.6651 10.5319 10.6651 9.81803C10.6651 9.10427 10.0843 8.5234 9.37044 8.5234V8.52343ZM9.37044 10.1059C9.21174 10.1059 9.08254 9.97682 9.08254 9.81803C9.08254 9.65933 9.21177 9.53026 9.37044 9.53026C9.5291 9.53026 9.65833 9.65933 9.65833 9.81803C9.65833 9.97682 9.52913 10.1059 9.37044 10.1059ZM7.23873 6.50931C6.96073 6.50931 6.73543 6.73464 6.73543 7.01258V12.6235C6.73543 12.9015 6.96077 13.1268 7.2387 13.1268C7.51664 13.1268 7.74217 12.9015 7.74217 12.6236V7.01258C7.74217 6.73458 7.51683 6.50931 7.23873 6.50931Z"
            fill="#000"
          />
          <path
            d="M16.843 0.214286C16.6464 0.0176988 16.3276 0.0178291 16.131 0.214286L14.3369 2.00854C13.5579 1.38711 12.5991 1.04986 11.5881 1.04986H11.5783C10.4014 1.0524 9.29488 1.51211 8.46273 2.34423L0.64913 10.1578C0.215264 10.5917 0.215264 11.2977 0.64913 11.7316L5.32556 16.4081C5.53589 16.6182 5.81526 16.734 6.11243 16.734C6.40974 16.734 6.68924 16.6183 6.89943 16.4081L14.7129 8.59453C15.5452 7.76238 16.0049 6.65584 16.0074 5.47898C16.0096 4.46437 15.6721 3.50191 15.0487 2.72038L16.8428 0.926155C17.0396 0.729665 17.0396 0.410874 16.843 0.214286ZM15.0007 5.4768C14.9986 6.38562 14.6436 7.24003 14.0011 7.88269L6.1875 15.6963C6.1777 15.7062 6.16601 15.714 6.15312 15.7194C6.14023 15.7247 6.12641 15.7274 6.11247 15.7273C6.09854 15.7274 6.08473 15.7246 6.07187 15.7193C6.05901 15.7139 6.04735 15.7061 6.03759 15.6961L1.36106 11.0197C1.34116 10.9998 1.32997 10.9729 1.32995 10.9447C1.32992 10.9166 1.34107 10.8896 1.36093 10.8697L9.17459 3.05623C9.81713 2.41357 10.6716 2.05854 11.5805 2.05662H11.5881C12.3296 2.05662 13.0348 2.29149 13.6192 2.72611L12.7 3.64537C12.0743 3.31639 11.266 3.40298 10.7215 3.94749C10.0632 4.60594 10.0632 5.67726 10.7215 6.33568C11.0405 6.65473 11.4646 6.83035 11.9157 6.83035C12.3667 6.83035 12.7909 6.6546 13.1098 6.33568C13.4287 6.01676 13.6045 5.59269 13.6045 5.14154C13.6045 4.8638 13.5376 4.59628 13.4118 4.35721L14.3311 3.43811C14.7672 4.02442 15.0022 4.73244 15.0007 5.47687V5.4768ZM12.398 4.6593C12.4615 4.72247 12.5119 4.79762 12.5461 4.88039C12.5804 4.96316 12.598 5.05191 12.5977 5.14151C12.5977 5.3237 12.5268 5.49503 12.398 5.62381C12.3348 5.68729 12.2596 5.73762 12.1769 5.77189C12.0941 5.80616 12.0054 5.82368 11.9158 5.82346C11.7336 5.82346 11.5623 5.75249 11.4334 5.62381C11.1676 5.35779 11.1676 4.92522 11.4334 4.6593C11.7 4.39279 12.1313 4.39279 12.398 4.6593H12.398Z"
            fill="#000"
          />
        </svg>

        <h1>{{ $t("APPS.SALES_MODULE") }}</h1>
      </div>
      <div class="elite-content_body_inner_header_right">
        <button><i class="far fa-ellipsis-h"></i></button>
      </div>-->
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
