export default {
  FUEL_TYPES: "Types de carburant",

  GAS: "Essence",
  HYBRID: "Hybride",
  ELECTRIC: "Électrique",
  PHEV: "Hybride Rechargeable",
  DIESEL: "Diesel",
  TEST: "TEST",
  CNG: "Gaz naturel comprimé",
};
