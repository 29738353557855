import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/inspection-form-multipliers`, options)
    .then((response) => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta,
      };
    });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/inspection-form-multipliers/${id}?include=`, options)
    .then((response) => {
      let inspectionFormMultiplier = jsona.deserialize(response.data);
      delete inspectionFormMultiplier.links;
      return inspectionForm;
    });
}

function add(inspectionFormMultiplier) {
  const payload = jsona.serialize({
    stuff: inspectionFormMultiplier,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/inspection-form-multipliers`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(inspectionFormMultiplier) {
  const payload = jsona.serialize({
    stuff: inspectionFormMultiplier,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/inspection-form-multipliers/${inspectionFormMultiplier.id}`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/inspection-form-multipliers/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
