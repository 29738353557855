export default {
  USERS_LIST: "Liste des utilisateurs",
  ADD_USER: "Ajouter un utilisateur",
  ADD_USERS: "Ajouter des utilisateurs",
  USER_ADDED: "Utilisateur ajouté",
  IS_STAFF: "Admin",
  ALL_IS_STAFF: "Tous types",
  AUTHORIZATIONS: "Autorisations",
  EDIT_USER: "Modifier l'utilisateur",
  USER_UPDATED: "Utilisateur modifié",
  DELETE_THIS_USER: "Supprimer cet utilisateur?",
  REMOVE_THIS_USER: "Retirer cet utilisateur?",
  USER_DELETED: "Utilisateur supprimé",
  ACTIVE: "Actif",
  DETACH: "Enlever",
  ACTIVATE: "Activer",
  DEACTIVATE: "Désactiver",
  USER_ACTIVATED: "Utilisateur Activé",
  USER_DEACTIVATED: "Utilisateur Désactivé",
  RESTRICT_TO_LOCATIONS: "Restreindre aux emplacements",
  CANNOT_SELF_DELETE: "Impossible de supprimer l'utilisateur connecté",
  PASSWORD_COMPLEXITY:
    "Le mot de passe doit contenir: des chiffres, des lettres, des majuscules, des minuscules, et faire au moins 8 caractères.",
  VIEW: "Utilisateur",
  SECURITY: "Sécurité",
};
