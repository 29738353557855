import service from "@/store/services/vehicles-estimate-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  estimate: {},
  url: null,
});

export const mutations = {
  SET_RESOURCE: (state, estimate) => {
    state.estimate = estimate;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((estimate) => {
      commit("SET_RESOURCE", estimate);
    });
  },
};

const getters = {
  estimate: (state) => state.estimate,
  url: (state) => state.url,
};

const vehiclesEstimate = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default vehiclesEstimate;
