import common from "./common";
import apps from "./apps";
import errors from "./errors";
import organizations from "./organizations";
import resellers from "./resellers";
import users from "./users";
import roles from "./roles";
import permissions from "./permissions";
import products from "./products";
import resellerInvoices from "./resellerInvoices";
import resellerPayments from "./resellerPayments";
import resellerProduct from "./resellerProduct";
import resellerServices from "./resellerServices";
import invoices from "./invoices";
import customers from "./customers";
import packages from "./packages";
import subscriptions from "./subscriptions";
import payments from "./payments";
import profile from "./profile";
import requests from "./requests";
import inspections from "./inspections";
import inspectionForms from "./inspection-forms";
import contacts from "./contacts";
import suppliers from "./suppliers";
import locations from "./locations";
import files from "./files";
import notifications from "./notifications";
import purchasesOrders from "./purchasesOrders";
import purchasesInvoices from "./purchasesInvoices";
import purchasesDeliveries from "./purchasesDeliveries";
import purchasesPayments from "./purchasesPayments";
import salesOrders from "./salesOrders";
import salesInvoices from "./salesInvoices";
import salesDeliveries from "./salesDeliveries";
import salesPayments from "./salesPayments";
import warehouses from "./warehouses";
import stockMovements from "./stockMovements";
import imports from "./imports";
import vehicles from "./vehicles";
import estimates from "./estimates";
import estimateVehicles from "./estimate-vehicles";
import veeValidateErrors from "./vee-validate-errors";
import provinces from "./provinces";
import fuelTypes from "./fuelTypes";
import transmissions from "./transmissions";
import drivetrain from "./drivetrain";
import odometers from "./odometers";
import vehicleClassCode from "./vehicleClassCode";
import options from "./options";
import repairs from "./repairs";

export default {
  COMMON: common,
  APPS: apps,
  ERRORS: errors,
  USERS: users,
  ORGANIZATIONS: organizations,
  RESELLERS: resellers,
  ROLES: roles,
  PERMISSIONS: permissions,
  PRODUCTS: products,
  INVOICES: invoices,
  CUSTOMERS: customers,
  SUBSCRIPTIONS: subscriptions,
  PACKAGES: packages,
  PAYMENTS: payments,
  PROFILE: profile,
  REQUESTS: requests,
  INSPECTIONS: inspections,
  INSPECTION_FORMS: inspectionForms,
  CONTACTS: contacts,
  SUPPLIERS: suppliers,
  LOCATIONS: locations,
  FILES: files,
  NOTIFICATIONS: notifications,
  PURCHASES_ORDERS: purchasesOrders,
  PURCHASES_INVOICES: purchasesInvoices,
  PURCHASES_DELIVERIES: purchasesDeliveries,
  PURCHASES_PAYMENTS: purchasesPayments,
  SALES_ORDERS: salesOrders,
  SALES_INVOICES: salesInvoices,
  SALES_DELIVERIES: salesDeliveries,
  SALES_PAYMENTS: salesPayments,
  RESELLER_PRODUCTS: resellerProduct,
  RESELLER_SERVICES: resellerServices,
  RESELLER_INVOICES: resellerInvoices,
  RESELLER_PAYMENTS: resellerPayments,
  WAREHOUSES: warehouses,
  STOCK_MOVEMENTS: stockMovements,
  IMPORTS: imports,
  VEHICLES: vehicles,
  ESTIMATES: estimates,
  ESTIMATE_VEHICLES: estimateVehicles,
  PROVINCES: provinces,
  FUEL_TYPES: fuelTypes,
  TRANSMISSIONS: transmissions,
  DRIVETRAIN: drivetrain,
  ODOMETERS: odometers,
  VEHICLES_CLASSES: vehicleClassCode,
  REPAIRS: repairs,
  OPTIONS: options,
  validations: {
    ...veeValidateErrors,
  },
};
