export default {
  DEFAULT_MODULE: "Application",
  ADMIN: "Administration",
  INSPECTPRO: "Inspecpros",
  VEHICLES: "Véhicules",
  CRM_MODULE: "Clients",
  SALES_MODULE: "Ventes",
  PURCHASES_MODULE: "Achats",
  INVENTORY_MODULE: "Inventaire",
  FILE_EXPLORER_MODULE: "Explorateur de fichiers",
  ADMIN_MODULE: "Administration",
  DASHBOARD: "Tableau de bord",
  RESELLER_MODULE: "Revendeur",
  VEHICLES_MODULE: "Véhicules",
};
