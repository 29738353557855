export default {
  REQUESTS_LIST: "Liste des demandes",
  ADD_REQUEST: "Ajouter une demande",
  REQUEST_ADDED: "Demande ajoutée",
  EDIT_REQUEST: "Modifier une demande",
  REQUEST_UPDATED: "Demande mis a jour",
  DELETE_THIS_REQUEST: "Supprimer cette demande?",
  REQUEST_DELETED: "Demande supprimée",
  EXPIRATION_TIME: "Date d'expiration",
  REQUEST_TYPE: "Type",
  REQUEST_TYPE_INSPECTIONS: "Inspection",
  EXPIRATION_DATE: "Date limite",
  CREATOR: "Créateur",
  REQUEST_STATUS_DRAFT: "Brouillon",
  REQUEST_STATUS_ASSIGNED: "Assignée",
  REQUEST_STATUS_APPROVED: "Approuvée",
  REQUEST_STATUS_COMPLETED: "Complétée",
  REQUEST_STATUS_CANCELED: "Annulée",
  ADD_INSPECTION: "Ajouter une inspection",
  ASSIGN_REQUEST: "Assigner la demande",
  ASSIGNED_TO: "Assignée à",
  ASSIGNED_AT: "Assignée le",
  COMPLETED_AT: "Complétée le",
  INSPECTION: "Inspection",
  CANCEL_REQUEST: "Annuler la demande",
  REQUEST_CANCELED: "Demande annulée",
  REQUEST_ASSIGNED: "Demande assignée",
  SHOW_INSPECTION: "Voir l'inspection",
  VIEW_REQUEST: "Demande",
  COMPLETE_REQUEST: "Terminer la demande",
  REQUEST_COMPLETED: "Demande terminée",
  ALL: "Toutes les demandes",
  ASSIGNED_TO_ME: "Assignées à moi",
  CREATED_BY_ME: "Créées par moi",
  APPROVE_REQUEST: "Approuver la demande",
  APPROVED_AT: "Approuvée le",
  APPROVED_BY: "Approuvée par",
  ASSIGNED_BY: "Assignée par",
  CANCELED_BY: "Annulée par",
  CANCELED_AT: "Annulée le",
  ADD_RESPONSE_OBJECT: "Traiter la demande",
  COMPLETED_BY: "Complétée par",
  SHOW_RESPONSE_OBJECT: "Voir le résultat",
  VEHICLE_VIN: "Vin du véhicule",
  REQUEST_APPROVED: "Demande approuvée",
  VEHICLE_SKU: "# stock",
  REQUEST_PRIORITY_10: "Basse",
  REQUEST_PRIORITY_20: "Moyenne",
  REQUEST_PRIORITY_30: "Élevée",
  REQUEST_TYPE_EVALUATIONS: "Évaluation",
  REQUEST_TYPE_REPARATIONS: "Réparation",
  ADD_QUICK_REQUEST: "Ajouter une demande rapide",

  ASSIGNED: "Assignées",
  COMPLETED: "Complétées",
  CANCELED: "Annulées",
  CREATED_AT: "Créée le",
  UPDATED_AT: "Mise à jour le",
};
