import Vue from "vue";
import Vuex from "vuex";

import appConfig from "./modules/app-config-module";
import auth from "./modules/auth";
import files from "./modules/files-module";
import folders from "./modules/folders-module";
// import alerts from "./modules/alerts-module";
import reset from "./modules/reset";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import roles from "./modules/roles-module";
import permissions from "./modules/permissions-module";
import organizations from "./modules/organizations-module";
import resellers from "./modules/resellers-module";
import resellerProducts from "./modules/reseller-products-module";
import resellerServices from "./modules/reseller-services-module";
import resellerInvoices from "./modules/reseller-invoices-module";
import resellerInvoiceItems from "./modules/reseller-invoice-items-module";
import resellerPayments from "./modules/reseller-payments-module";
import packages from "./modules/packages-module";
import customers from "./modules/customers-module";
import logs from "./modules/logs-module";
import subscriptions from "./modules/subscriptions-module";
import payments from "./modules/payments-module";
import contacts from "./modules/contacts-module";
import suppliers from "./modules/suppliers-module";
import tags from "./modules/tags-module";
import locations from "./modules/locations-module";
import notifications from "./modules/notifications-module";
import notificationSubscriptions from "./modules/notification-subscriptions-module";
import products from "./modules/products-module";
import warehouses from "./modules/warehouses-module";
import warehouseProducts from "./modules/warehouse-products-module";
import purchasesOrders from "./modules/purchases-orders-module";
import purchasesOrderItems from "./modules/purchases-order-items-module";
import purchasesInvoices from "./modules/purchases-invoices-module";
import purchasesInvoiceItems from "./modules/purchases-invoice-items-module";
import purchasesDeliveries from "./modules/purchases-deliveries-module";
import purchasesDeliveryItems from "./modules/purchases-delivery-items-module";
import purchasesPayments from "./modules/purchases-payments-module";
import salesOrders from "./modules/sales-orders-module";
import salesOrderItems from "./modules/sales-order-items-module";
import salesInvoices from "./modules/sales-invoices-module";
import salesInvoiceItems from "./modules/sales-invoice-items-module";
import salesDeliveries from "./modules/sales-deliveries-module";
import salesDeliveryItems from "./modules/sales-delivery-items-module";
import salesPayments from "./modules/sales-payments-module";
import supplierProducts from "./modules/supplier-products-module";
import stockMovements from "./modules/stock-movements-module";
import stockMovementItems from "./modules/stock-movement-items-module";
import requests from "./modules/requests-module";
import inspectionForms from "./modules/inspection-forms-module";
import inspectionFormSections from "./modules/inspection-form-sections-module";
import inspectionFormSectionFields from "./modules/inspection-form-section-fields-module";
import inspections from "./modules/inspections-module";
import inspectionFields from "./modules/inspection-fields-module";
import imports from "@/store/modules/imports-module";
import vehicles from "@/store/modules/vehicles-module";
import estimations from "@/store/modules/estimations-module";
import inspectionFormMultipliers from "@/store/modules/inspection-form-multipliers-module";
import estimationItems from "@/store/modules/estimation-items-module";
import vehiclesEstimate from "@/store/modules/vehicles-estimate-module";
import inspectionFormNote from "@/store/modules/inspection-form-notes-module";
import inspectionFormReminder from "@/store/modules/inspection-form-reminders-module";
import estimateVehicleMake from "@/store/modules/estimate-vehicle-makes-module";
import estimateVehicleYear from "@/store/modules/estimate-vehicle-years-module";
import estimateVehicleModel from "@/store/modules/estimate-vehicle-models-module";
import estimateVehicleSerie from "@/store/modules/estimate-vehicle-series-module";
import estimateVehicleStyle from "@/store/modules/estimate-vehicle-styles-module";
import requestLogs from "@/store/modules/request-logs-module";
import domains from "@/store/modules/estimate-vehicle-allow-domains-module";
import comments from "./modules/comments-module";
import options from "./modules/options-module";
import organizationOptions from "./modules/organization-options-module";
import taxGroups from "./modules/tax-groups-module";
import repairs from "./modules/repairs-module";
import repairItems from "./modules/repair-items-module";
import routes from "./modules/routes";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    appConfig,
    auth,
    files,
    folders,
    // alerts,
    reset,
    profile,
    users,
    roles,
    permissions,
    organizations,
    resellers,
    resellerServices,
    resellerProducts,
    resellerInvoices,
    resellerInvoiceItems,
    resellerPayments,
    customers,
    logs,
    packages,
    subscriptions,
    payments,
    contacts,
    suppliers,
    tags,
    locations,
    notifications,
    notificationSubscriptions,
    products,
    warehouses,
    warehouseProducts,
    purchasesOrders,
    purchasesOrderItems,
    purchasesInvoices,
    purchasesInvoiceItems,
    purchasesDeliveries,
    purchasesDeliveryItems,
    purchasesPayments,
    salesOrders,
    salesOrderItems,
    salesInvoices,
    salesInvoiceItems,
    salesDeliveries,
    salesDeliveryItems,
    salesPayments,
    supplierProducts,
    stockMovements,
    stockMovementItems,
    requests,
    inspectionForms,
    inspectionFormSections,
    inspectionFormSectionFields,
    inspections,
    inspectionFields,
    imports,
    vehicles,
    estimations,
    inspectionFormMultipliers,
    estimationItems,
    vehiclesEstimate,
    inspectionFormNote,
    inspectionFormReminder,
    estimateVehicleMake,
    estimateVehicleYear,
    estimateVehicleModel,
    estimateVehicleSerie,
    estimateVehicleStyle,
    requestLogs,
    domains,
    comments,
    options,
    organizationOptions,
    taxGroups,
    repairs,
    repairItems,
    routes,
  },
});
