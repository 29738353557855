export default {
  LOCATIONS_LIST: "Liste des emplacements",
  ADD_LOCATION: "Ajouter un emplacement",
  LOCATION_ADDED: "Emplacement ajouté",
  LOCATIONS_UPDATED: "Emplacement modifié",
  EDIT_LOCATION: "Modifier cet emplacement",
  DELETE_THIS_LOCATION: "Supprimer cet emplacement?",
  LOCATION_DELETED: "Emplacement supprimé",
  VIEW_LOCATION: "Emplacement",
};
