<template>
  <div class="wrapper">
    <div class="elite-content_body_inner_header">
      <!--<div class="elite-content_body_inner_header_left">
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.85389 1.52539C3.66045 1.5254 3.47082 1.57927 3.30627 1.68098C3.14172 1.78268 3.00874 1.9282 2.92223 2.10122L2.16848 3.60872H15.146L14.3918 2.10122C14.3054 1.92833 14.1725 1.78289 14.0081 1.6812C13.8437 1.5795 13.6543 1.52555 13.461 1.52539H3.85389ZM15.5322 4.85872H1.78223V14.2337C1.78223 14.51 1.89197 14.7749 2.08732 14.9703C2.28267 15.1656 2.54763 15.2754 2.82389 15.2754H14.4906C14.7668 15.2754 15.0318 15.1656 15.2271 14.9703C15.4225 14.7749 15.5322 14.51 15.5322 14.2337V4.85872ZM1.80431 1.54206C1.99468 1.16138 2.2873 0.841244 2.64938 0.61752C3.01146 0.393796 3.42869 0.275328 3.85431 0.275391H13.4606C13.8862 0.275328 14.3034 0.393796 14.6655 0.61752C15.0276 0.841244 15.3202 1.16138 15.5106 1.54206L16.7164 3.95456C16.7597 4.04125 16.7822 4.13682 16.7822 4.23372V14.2337C16.7822 14.8415 16.5408 15.4244 16.111 15.8542C15.6812 16.2839 15.0983 16.5254 14.4906 16.5254H2.82389C2.21611 16.5254 1.63321 16.2839 1.20344 15.8542C0.773669 15.4244 0.532227 14.8415 0.532227 14.2337V4.23372C0.532233 4.13682 0.554771 4.04125 0.59806 3.95456L1.80431 1.54206Z"
            fill="#000"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.94873 12.5669C5.94873 12.4011 6.01458 12.2422 6.13179 12.125C6.249 12.0077 6.40797 11.9419 6.57373 11.9419H10.7404C10.9062 11.9419 11.0651 12.0077 11.1823 12.125C11.2995 12.2422 11.3654 12.4011 11.3654 12.5669C11.3654 12.7327 11.2995 12.8916 11.1823 13.0088C11.0651 13.126 10.9062 13.1919 10.7404 13.1919H6.57373C6.40797 13.1919 6.249 13.126 6.13179 13.0088C6.01458 12.8916 5.94873 12.7327 5.94873 12.5669Z"
            fill="#000"
          />
        </svg>

        <h1>{{ $t("APPS.INVENTORY_MODULE") }}</h1>
      </div>
      <div class="elite-content_body_inner_header_right">
        <button><i class="far fa-ellipsis-h"></i></button>
      </div>-->
    </div>
    <div class="elite-content_body_inner_container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
