export default {
  INSPECTION_FORMS_LIST: "Liste des formulaires",
  ADD_INSPECTION_FORM: "Ajouter un formulaire d'inspection",
  INSPECTION_FORM_ADDED: "Formulaire d'inspection ajouté",
  EDIT_INSPECTION_FORM: "Modifier un formulaire d'inspection",
  INSPECTION_FORM_UPDATED: "Formulaire d'inspection mis a jour",
  DELETE_THIS_INSPECTION_FORM: "Supprimer ce formulaire d'inspection?",
  INSPECTION_FORM_DELETED: "Formulaire d'inspection supprimé",
  INSPECTION_FORM_TYPE_INSPECTION_FORMS: "Formulaire d'inspection",
  ADD_INSPECTION_FORM_SECTION: "Ajouter une section",
  FORM: "Formulaire",
  EDIT_INSPECTION_FORM_SECTION: "Modifier une section",
  INSPECTION_FORM_SECTION_ADDED: "Section ajoutée",
  UPDATE_INSPECTION_FORM_SECTION: "Modifier la section",
  DELETE_INSPECTION_FORM_SECTION: "Supprimer la section",
  INSPECTION_FORM_SECTION_UPDATED: "Section modifiée",
  DELETE_THIS_INSPECTION_FORM_SECTION: "Supprimer cette section?",
  INSPECTION_FORM_SECTION_DELETED: "Section supprimée",
  ADD_INSPECTION_FORM_SECTION_FIELD: "Ajouter un champ",
  FIELD_TYPE_SELECT_SINGLE: "Choix unique",
  FIELD_TYPE_SELECT_MULTIPLE: "Choix multiple",
  FIELD_TYPE_NUMBER: "Nombre",
  FIELD_TYPE_TEXT: "Texte",
  FIELD_TYPE_RANGE_SLIDER: "Slider",
  FIELD_TYPE_CHECK: "Case a cocher",
  FIELD_TYPE_STATE_WIDGET: "Widget d’état",
  FIELD_TYPE_TIRE_STATE_WIDGET: "Widget slider état pneu",
  FIELD_TYPE_BATTERY_STATE_WIDGET: "Widget d’état de batterie",
  FIELD_TYPE_STATE_WIDGET: "Widget d’état",
  FIELD_TYPE_TIRE_STATE_WIDGET: "Widget slider état pneu",
  FIELD_TYPE_DRUM_THICKNESS_WIDGET: "Épaisseur des tambours",
  FIELD_TYPE_PAD_THICKNESS_WIDGET: "Épaisseur des plaquettes",
  FIELD_TYPE_BATTERY_STATE_WIDGET: "Widget d’état de batterie",
  CAN_UPLOAD_IMAGE: "Peut ajouter des images",
  CAN_ADD_COMMENT: "Peut ajouter un commentaire",
  FIELD_ICON: "Icône / Image",
  FIELD_POINTS_THRESHOLD: "Options et points",
  FIELD_POINTS_THRESHOLD_OPTION: "Option",
  FIELD_POINTS_THRESHOLD_POINTS: "Points",
  DELETE_INSPECTION_FORM_SECTION_FIELD: "Supprimer le champ",
  UPDATE_INSPECTION_FORM_SECTION_FIELD: "Modifier le champ",
  FIELD_POINTS_INSTRUCTIONS_CHECK: "",
  FIELD_POINTS_INSTRUCTIONS_SELECT_SINGLE: "",
  FIELD_POINTS_INSTRUCTIONS_SELECT_MULTIPLE: "",
  FIELD_POINTS_INSTRUCTIONS_NUMBER:
    "Entrer les options sous le format <min valeur>-<max valeur> exemple 1-10 veut dire de 1 à 10 (1 et 10 inclus)",
  FIELD_POINTS_INSTRUCTIONS_TEXT: "",
  FIELD_POINTS_INSTRUCTIONS_RANGE_SLIDER:
    "Entrer les options sous le format <min valeur>-<max valeur> exemple 1-10 veut dire de 1 à 10 (1 et 10 inclus)",
  FIELD_POINTS_INSTRUCTIONS_STATE_WIDGET:
    "Le widget d’état a 4 options: bon, a remplacer, mauvais, non applicable, renseigner les points pour chacune des options",
  FIELD_POINTS_INSTRUCTIONS_TIRE_STATE_WIDGET:
    "Le widget d’état de pneu est un slider avec une couleur",
  FIELD_POINTS_INSTRUCTIONS_BATTERY_STATE_WIDGET:
    "Le widget d’état a 4 options: bon, a remplacer, mauvais, non applicable, renseigner les points pour chacune des options",
  ADD_INSPECTION_FORM_SECTION_FIELD: "Ajouter un champ",
  TOTAL_POINTS: "Total points",
  MAX_POINTS: "Points max",
  SECTIONS_COUNT: "Nombre de sections",
  FIELDS_COUNT: "Nombre de champs",
  VIEW_INSPECTION_FORM: "Formulaire d'inspection",
  DUPLICATE_THIS_INSPECTION_FORM: "Dupliquer ce formulaire d'inspection?",
  DUPLICATE: "Dupliquer",
  NEW_INSPECTION_FORM_SECTION: "Nouvelle section",
  EDIT_INSPECTION_FORM_SECTION: "Modifier la section",
  NEW_INSPECTION_FORM_SECTION_FIELD: "Nouveau champ",
  EDIT_INSPECTION_FORM_SECTION_FIELD: "Modifier le champ",
  OPTION: "Option",
  TYPE: "Type",
  EXCERPT: "Description",
  ADD_INSPECTION_FORM_SUB_SECTION: "Ajouter une sous section",
  FACTOR: "Coefficient",
  FACTOR_ABBREV: "Coeff",
  ENABLE_NA: "Activer le N/A",
  SYNC: "Synchroniser",
  SYNC_PREVIEW: "Aperçu de synchronisation",
  FIELDS_CREATED: "Crées",
  FIELDS_UPDATED: "Mis à jour",
  FIELDS_DELETED: "Supprimés",
  FIELDS_ERRORS: "Erreurs",
  SYNC_RESULTS: "Résultats de synchronisation",
  RUN_SYNCHRONISATION: "Lancer la synchronisation",
  VIEW_DEMO: "Voir la demo",
  IMPAIRS_PROPER_FUNCTIONNING: "Nuit au bon fonctionnement",
  MAJOR: "Majeur",
  MEAN_REPAIR_VALUE: "Valeur moyenne des reparations",
  VEHICLE_PRICE: "Prix du véhicule",
  GLOBAL_POINTS: "Global points",
  REPAIR_AMOUNT: "Montant estimé des reparations",
  SET_ALL_GOOD: "Mettre tout a bon",
  SET_ALL_WARNING: "Mettre tout a prévoir",
  SET_ALL_BAD: "Mettre tout a réparer",
  FINAL_SCORE: "Note finale",
  INSPECTION_FORM_MULTIPLIERS: "Multiplicateurs",
  ADD_INSPECTION_FORM_MULTIPLIER: "Ajouter un multiplicateur",
  NEW_INSPECTION_FORM_MULTIPLIER: "Nouveau multiplicateur",
  MULTIPLIER_TYPE: "Type",
  MULTIPLIER_TYPE_POINTS: "Points",
  MULTIPLIER_TYPE_REPAIR_VALUE: "Montant des reparations",
  MULTIPLIER_FIELD_MAKE: "Marque",
  MULTIPLIER_FIELD_MODEL_YEAR: "Année",
  MULTIPLIER_FIELD_ODOMETER: "Odomètre",
  MULTIPLIER_FIELD: "Champ",
  MULTIPLIER_OPERATION: "Opération",
  MULTIPLIER_OPERATION_EQUALS: "Égal",
  MULTIPLIER_OPERATION_GREATER_THAN: "Plus grand que",
  MULTIPLIER_OPERATION_LESS_THAN: "Plus petit que",
  MULTIPLIER_OPERATION_CONTAINS: "Contient",
  MULTIPLIER_VALUE: "Valeur",
  MULTIPLIER_MULTIPLIER: "Multiplicateur",
  INSPECTION_FORM_MULTIPLIER_ADDED: "Multiplicateur ajouté",
  RETURN_TO_INSPECTION_FORM: "Retour au formulaire d'inspection",
  MULTIPLIERS: "Multiplicateurs",
  IS_COPY: "Référence",
  COPIED_FIELD: "Champ référence",
  ALL_FIELDS: "Tous les champs",
  COPY_OF_FIELD: "Référence à",
  INSPECTION_FORM_NOTES: "Notes",
  ADD_INSPECTION_FORM_NOTE: "Ajouter une note",
  NEW_INSPECTION_FORM_NOTE: "nouvelle note",
  INSPECTION_FORM_NOTE_MIN_VALUE: "Valeur minimum",
  INSPECTION_FORM_NOTE_MAX_VALUE: "Valeur maximum",
  INSPECTION_FORM_NOTE: "Note",
  DELETE_THIS_INSPECTION_FORM_NOTE: "Supprimer cette note?",
  INSPECTION_FORM_NOTE_ADDED: "Note ajoutée",
  INSPECTION_FORM_NOTE_UPDATED: "Note mis à jour",
  INSPECTION_FORM_NOTE_DELETED: "Note supprimée",
  RETURN_TO_INSPECTION_FORM_NOTES: "Retour au formulaire d'inspection",
  NO_VEHICLE_FOUND_FOR_VIN: "Aucun véhicule n'a été trouvé pour ce VIN",
  MULTIPLIER_FIELD_MODEL: "Modèle",
  MULTIPLIER_FIELD_YEAR: "Année",
  MULTIPLIER_FIELD_STYLE: "Style",
  EDIT_INSPECTION_FORM_MULTIPLIER: "Modifier le multiplicateur",
  UPDATE_INSPECTION_FORM_MULTIPLIER: "Modifier le multiplicateur",
  ADJUSTED_FINAL_SCORE: "Note finale ajustée",
  NO_PRICE_FOUND_FOR_VEHICLE: "Aucun prix trouvé pour ce véhicule",
  INSPECTION_FORM_REMINDER_ORDER: "Numéro d’ordre",
  ADD_INSPECTION_FORM_REMINDER: "Ajouter un rappel",
  INSPECTION_FORM_REMINDER_ADDED: "Rappel ajouté",
  INSPECTION_FORM_REMINDER_UPDATED: "Rappel mis à jour",
  DELETE_THIS_INSPECTION_FORM_REMINDER: "Supprimer ce rappel?",
  INSPECTION_FORM_REMINDER_DELETED: "Rappel supprimé",
  INSPECTION_FORM_REMINDERS: "Rappels",
  LINE: "Ligne",
  ERROR: "Erreur",
  FIELDS_UNTOUCHED: "Sans changements",
  CONDITIONAL_FIELD: "Conditionnel",
  CONDITION_SHOW: "Afficher/Cacher",
  SHOW: "Afficher",
  HIDE: "Cacher",
  CONDITION_RELATION: "Relation",
  RELATION_AND: "Et",
  RELATION_OR: "Ou",
  FIELD: "Champ",
  OPERATOR: "Opérateur",
  VALUE: "Valeur",
  OPERATOR_EQUALS: "Égal à",
  OPERATOR_DIFFERENT: "Different de",
  OPERATOR_SUPERIOR: "Supérieur à",
  OPERATOR_INFERIOR: "Inférieur à",
  FIELD_POINTS_INSTRUCTIONS_PAD_THICKNESS_WIDGET:
    "Widget d’épaisseur des plaquettes",
  FIELD_POINTS_INSTRUCTIONS_DRUM_THICKNESS_WIDGET:
    "Widget d’épaisseur des tambours",
  FUEL_TYPES: "Types de carburant",
};
