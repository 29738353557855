import permissions from "../constants/permissions";
import EmbedModuleLayout from "@/views/Pages/EmbedModule/EmbedModuleLayout.vue";

const GetVehicleEstimate = () =>
  import(
    "@/views/Pages/VehiclesModule/GetVehicleEstimate/components/ViewEstimateComponent.vue"
  );

let getVehicleEstimatePages = {
  path: "vehicle-estimate",
  components: {
    default: EmbedModuleLayout,
    // SidebarSecondLevelMenu: InspectproModuleMenu,
  },
  children: [
    {
      path: "",
      name: "Vehicle Estimate",
      components: { default: GetVehicleEstimate },
      meta: {},
    },
  ],
};

const embedModuleRoutes = [getVehicleEstimatePages];

export default embedModuleRoutes;
