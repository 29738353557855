import router from "../router";

export const setLastRouteOnStorage = (route) => {
  let routes = localStorage.getItem("lastRoute");

  routes = routes ? JSON.parse(routes) : {};

  const noSlashRoute = route.startsWith("/") ? route.slice(1) : route;

  const main = noSlashRoute.split("/")[0];

  if (!["", "/"].includes(main)) routes[main] = route;

  localStorage.setItem("lastRoute", JSON.stringify(routes));
};

export const getLastRouteFromStorage = (route) => {
  let routes = localStorage.getItem("lastRoute");

  routes = routes ? JSON.parse(routes) : {};

  let noSlashRoute = route.path.startsWith("/")
    ? route.path.slice(1)
    : route.path;

  noSlashRoute = noSlashRoute.endsWith("/")
    ? noSlashRoute.slice(0, -1)
    : noSlashRoute;

  const lastRoute = routes[noSlashRoute];

  if (!lastRoute) return route;

  return router.getRoutes().find((r) => r.path === lastRoute);
};
