import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/request-logs`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = ["user", "domain"];

  return axios
    .get(`${url}/request-logs/${id}?include=${includes.join(",")}`, options)
    .then((response) => {
      let estimation = jsona.deserialize(response.data);
      delete estimation.links;
      return estimation;
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/request-logs/${id}`, options);
}

export default {
  list,
  get,
  destroy,
};
