export default {
  PAYMENTS_LIST: "Liste des paiements",
  ADD_PAYMENT: "Ajouter un paiement",
  PAYMENT_ADDED: "Paiement ajouté",
  AUTHORIZATIONS: "Autorisations",
  EDIT_PAYMENT: "Modifier le paiement",
  PAYMENT_SOURCE_MANUAL: "Manuel",
  PAYMENT_SOURCE_PAYPAL: "PayPal",
  PAYMENT_SOURCE_STRIPE: "Stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Id transaction",
  TRANSACTION_DATA: "Données transaction",
  SOURCE_DETAILS: "Détails du paiement",
};
