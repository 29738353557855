import store from "../store";
import { i18n } from "./i18n";

const numberFormat = {
  install: (Vue) => {
    Vue.prototype.$numberFormat = (number, currency = null) => {
      if (isNaN(number)) return "-";
      const me = store.getters["profile/me"];

      const local = i18n.locale ?? "fr";

      let options = {};

      if (currency) {
        options = {
          style: "currency",
          currency,
        };
      }

      return new Intl.NumberFormat(`${local}-CA`, options).format(number);
    };
  },
};

export default numberFormat;
