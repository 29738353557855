import service from "@/store/services/repair-items-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({});

export const mutations = {};

export const actions = {
  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios);
  },
  add(_, params) {
    return service.add(params, this.$axios);
  },

  update(_, params) {
    return service.update(params, this.$axios);
  },

  destroy(_, params) {
    return service.destroy(params, this.$axios);
  },
};

const getters = {};

const repairItems = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default repairItems;
