export default {
  INVOICES_LIST: "Liste des factures",
  ADD_INVOICE: "Ajouter une facture",
  ADD_INVOICE_ITEM: "Ajouter une ligne",
  INVOICE_ADDED: "Facture ajoutée",
  OWNER: "Propriétaire",
  EDIT_INVOICE: "Modifier une facture",
  INVOICE_UPDATED: "Facture mise à jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_INVOICE_ITEM: "Supprimer la ligne",
  UPDATE_INVOICE_ITEM: "Modifier la ligne",
  INVOICE_ITEM_DELETED: "Ligne supprimée",
  INVOICE_ITEM_ADDED: "Ligne ajoutée",
  INVOICE_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  INVOICE_SENT: "Facture envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir l'organisation",
  DELETE_THIS_INVOICE: "Supprimer cette facture?",
  INVOICE_DELETED: "Facture supprimée",
  CANCEL_INVOICE_QUESTION: "Annuler la facture?",
  INVOICE_CANCELED: "Facture annulée",
};
