import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/vehicles`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/vehicles/${id}?include=organization,allowedLocations,customer,mecanic`,
      options
    )
    .then((response) => {
      let vehicle = jsona.deserialize(response.data);
      return vehicle;
    });
}

function add(vehicle) {
  const payload = jsona.serialize({
    stuff: vehicle,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/vehicles?include=organization,allowedLocations,customer,mecanic`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(vehicle) {
  const payload = jsona.serialize({
    stuff: vehicle,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/vehicles/${vehicle.id}?include=organization,allowedLocations,customer,mecanic`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/vehicles/${id}`, options);
}

function lookupApiByVin({ vin, organization }) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
    params: {
      organization,
    },
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/vehicles/get-by-vin/${vin}`, options)
    .then((response) => {
      let vehicles = response.data;
      return vehicles;
    });
}

function lookupWorstPriceByVin(vin) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/vehicles/get-worst-price-by-vin/${vin}`, options)
    .then((response) => {
      let vehicles = response.data;
      return vehicles;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  lookupApiByVin,
  lookupWorstPriceByVin,
};
