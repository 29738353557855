export default {
  CUSTOMERS_LIST: "Liste des clients",
  ADD_CUSTOMER: "Ajouter un client",
  CUSTOMER_ADDED: "Client ajouté",
  EDIT_CUSTOMER: "Modifier le client",
  CUSTOMER_UPDATED: "Client mis à jour",
  DELETE_THIS_CUSTOMER: "Supprimer ce client?",
  CUSTOMER_DELETED: "Client supprimé",
  BILLING_INFORMATIONS_SAME_AS_CUSTOMER: "Même adresse que le client",
  CUSTOMER_TYPE_INDIVIDUAL: "Particulier",
  CUSTOMER_TYPE_COMPANY: "Entreprise",
  VIEW_CUSTOMER: "Client",
  CUSTOMER_INFORMATION: "Informations sur le client",
  SELECT_CUSTOMER: "Sélectionner un client",
  EXISTING_CUSTOMER: "Client existant",
  ADD_EXISTING_CUSTOMER: "Ajouter un client existant",
  NEW_CUSTOMER: "Nouveau client",
  SELECT_CUSTOMER: "Sélectionner un client",
};
