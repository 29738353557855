import moment from "moment";
import "moment-timezone";
import { i18n } from "./i18n";

const timeZoneDateFormat = {
  install: (Vue) => {
    Vue.prototype.$timeZoneDateFormat = (value, format = "LLL") => {
      const momentDate = moment.tz(value, "UTC").tz(moment.tz.guess());
      return momentDate.locale(i18n.locale ?? "fr").format(format);
    };
    Vue.prototype.$getUtcDateTimeString = (value) => {
      return moment.tz(value, moment.tz.guess()).tz("UTC").format();
    };
    Vue.prototype.$tzTimeAgo = (value) => {
      const momentDate = moment.tz(value, "UTC").tz(moment.tz.guess());
      return momentDate.locale(i18n.locale ?? "fr").fromNow();
    };
  },
};

export default timeZoneDateFormat;
