<template>
  <router-link class="object-link" :to="$objectViewRoute(object)">
    {{ $objectDenomination(object) }}
    <i class="fas fa-external-link-alt"></i>
  </router-link>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "object-link",

  components: {},

  mixins: [formMixin],

  props: {
    object: {
      type: Object,
      default: () => ({}),
      description: "Object",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    object(object) {},
  },
};
</script>

<style scoped>
.object-link {
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
}

.object-link i {
  margin-left: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.object-link:hover {
  text-decoration: underline;
}

.object-link:hover i {
  opacity: 1;
}
</style>
