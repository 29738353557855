export default {
  SUPPLIERS_LIST: "Liste des fournisseurs",
  ADD_SUPPLIER: "Ajouter un fournisseur",
  SUPPLIER_ADDED: "Fournisseur ajouté",
  EDIT_SUPPLIER: "Modifier un fournisseur",
  SUPPLIER_UPDATED: "Fournisseur mis à jour updated",
  DELETE_THIS_SUPPLIER: "Supprimer ce fournisseur?",
  SUPPLIER_DELETED: "Fournisseur supprimé",
  BILLING_INFORMATIONS_SAME_AS_SUPPLIER: "Même adresse que le fournisseur",
  ADD_PRODUCT: "Ajouter un produit",
  VIEW_SUPPLIER: "Fournisseur",
};
