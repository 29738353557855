import Vue from "vue";

export default {
  data() {
    return {
      isLoading: false,
      apiValidationErrors: {},
    };
  },
  methods: {
    /* extract API server validation errors and assigns them to local mixin data */
    setApiValidation(serverErrors, refs = null) {
      if (serverErrors.attachment) {
        this.apiValidationErrors = { attachment: [serverErrors.attachment[0]] };
        return { ["attachment"]: serverErrors.attachment[0] };
      }
      this.apiValidationErrors = serverErrors.reduce(
        (accumulator, errorObject) => {
          if (typeof errorObject.source === "undefined") return false;

          const errorFieldName = errorObject.source.pointer.split("/")[3];
          const errorDetail = (accumulator[errorFieldName] || []).concat(
            errorObject.detail
          );

          return {
            ...accumulator,
            [errorFieldName]: errorDetail,
          };
        },
        {}
      );

      if (!Vue.prototype.isScrollingToError) {
        this.scrollToFirstError();
      }
    },

    scrollToFirstError() {
      if (document.querySelector("span.form-error > small.argon-error")) {
        Vue.prototype.isScrollingToError = true;
        setTimeout(() => {
          this.$scrollTo(
            document.querySelector("span.form-error > small.argon-error"),
            {
              onDone: () => {
                Vue.prototype.isScrollingToError = false;
              },
            }
          );
        }, 200);
      }
    },

    resetApiValidation() {
      this.apiValidationErrors = {};
    },
  },
};
