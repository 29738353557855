export const PHONE_TYPE_MOBILE = "MOBILE";
export const PHONE_TYPE_HOME = "HOME";
export const PHONE_TYPE_OFFICE = "OFFICE";
export const PHONE_TYPE_BUSINESS = "BUSINESS";
export const PHONE_TYPE_FAX = "FAX";
export const PHONE_TYPE_PAGER = "PAGER";
export const PHONE_TYPE_OTHER = "OTHER";

export const phoneTypesOptions = {
  [PHONE_TYPE_MOBILE]: "MOBILE",
  [PHONE_TYPE_HOME]: "HOME",
  [PHONE_TYPE_OFFICE]: "OFFICE",
  [PHONE_TYPE_BUSINESS]: "BUSINESS",
  [PHONE_TYPE_FAX]: "FAX",
  [PHONE_TYPE_PAGER]: "PAGER",
  [PHONE_TYPE_OTHER]: "OTHER",
};

export const QUERY_ACTIONS_VIEW = "view";
export const QUERY_ACTIONS_EDIT = "edit";
export const QUERY_ACTIONS_ADD = "add";
export const QUERY_ACTIONS_ADD_QUICK_REQUEST = "add_quick_request";
