export default {
  SOMETHING_WENT_WRONG: "Une erreur est survenue",
  PAGE_NOT_ALLOWED: "Vous n'êtes pas autorisé à accéder à cette page",
  FILE_UPLOAD_ERROR: "Erreur lors du chargement du fichier",
  NOT_FOUND_ERROR_WITH_ID:
    "{resource} {id} n'existe pas ou n’avez pas le droit d’y accéder",
  NOT_FOUND_ERROR: "La resource n'existe pas",
  FORBIDDEN_ERROR:
    "Vous n’avez pas la permission d’accéder ou d’effectuer cette action",
  UNAUTHORIZED_ERROR: "Veuillez vous connecter à l'application",
  INTERNAL_SERVER_ERROR: "Oups quelque chose s’est mal passé",
  ERRORS: {
    UNAUTHORIZED: "Non autorisé",
    FORBIDDEN: "Accès Refusé",
    NOT_FOUND: "Non trouvé ou Accès refusé",
    SERVER_ERROR: "Oups...",
  },
  NO_ORGANIZATION: "Vous n’avez pas d’organisation",
};
