import permissions from "../constants/permissions";
import VehiclesModuleLayout from "@/views/Pages/VehiclesModule/VehiclesModuleLayout.vue";
import VehiclesModuleMenu from "@/views/Pages/VehiclesModule/VehiclesModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

const VehiclesPage = () =>
  import("@/views/Pages/VehiclesModule/VehiclesPage.vue");

const ListRequestPage = () =>
  import("@/views/Pages/VehiclesModule/RequestManagement/ListRequestPage.vue");

const ListVehiclePage = () =>
  import("@/views/Pages/VehiclesModule/VehicleManagement/ListVehiclePage.vue");

const GetVehicleEstimate = () =>
  import(
    "@/views/Pages/VehiclesModule/GetVehicleEstimate/GetVehicleEstimate.vue"
  );

const RequestLogPage = () =>
  import(
    "@/views/Pages/VehiclesModule/RequestLogManagement/ListRequestLogPage.vue"
  );

let requestManagementPages = {
  path: "requests",
  components: {
    default: VehiclesModuleLayout,
    SidebarSecondLevelMenu: VehiclesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Requests",
      components: { default: ListRequestPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_REQUESTS,
        hasLevel2: true,
      },
    },
  ],
};

let vehicleManagementPages = {
  path: "vehicles",
  components: {
    default: VehiclesModuleLayout,
    SidebarSecondLevelMenu: VehiclesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Vehicles",
      components: { default: ListVehiclePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_VEHICLES,
        hasLevel2: true,
      },
    },
  ],
};

let getVehicleEstimatePages = {
  path: "vehicle-estimate",
  components: {
    default: VehiclesModuleLayout,
    SidebarSecondLevelMenu: VehiclesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "Get Vehicle Estimate",
      components: { default: GetVehicleEstimate },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_MODULE_VEHICLE_ESTIMATIONS,
        hasLevel2: true,
      },
    },
  ],
};

let requestLogsPages = {
  path: "request-logs",
  components: {
    default: VehiclesModuleLayout,
    SidebarSecondLevelMenu: VehiclesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Request Logs",
      components: { default: RequestLogPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_REQUEST_LOGS,
        hasLevel2: true,
      },
    },
  ],
};

const vehiclesModuleRoutes = [
  {
    path: "",
    components: {
      default: VehiclesModuleLayout,
      SidebarSecondLevelMenu: VehiclesModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Vehicles Page",
        components: { default: VehiclesPage },
        meta: {
          middleware: authenticated,
          permission: permissions.PERM_VIEW_APP_VEHICLES,
          hasLevel2: true,
        },
      },
    ],
  },
  requestManagementPages,
  vehicleManagementPages,
  getVehicleEstimatePages,
  requestLogsPages,
];

export default vehiclesModuleRoutes;
