export default {
  RESELLER_SERVICES_LIST: "Liste des services",
  ADD_RESELLER_SERVICE: "Ajouter un service",
  RESELLER_SERVICE_ADDED: "Service ajouté",
  EDIT_RESELLER_SERVICE: "Modifier un service",
  RESELLER_SERVICE_UPDATED: "Service mis à jour",
  DELETE_THIS_RESELLER_SERVICE: "Supprimer ce service?",
  RESELLER_SERVICE_DELETED: "Service supprimé",
  VIEW_RESELLER_SERVICE: "Service",
};
