import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

const includes = [];

function get(itemId) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/estimation-items/${itemId}?include=${includes.join(",")}`,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function add(item) {
  const payload = jsona.serialize({
    stuff: item,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/estimation-items`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(item) {
  const payload = jsona.serialize({
    stuff: item,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/estimation-items/${item.id}?include=${includes.join(",")}`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(itemId) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/estimation-items/${itemId}`, options);
}

export default {
  get,
  add,
  update,
  destroy,
};
