import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/inspection-forms`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = [
    "organization",
    "allowedLocations",
    "tags",
    "inspectionFormSections",
    "inspectionFormMultipliers",
    "inspectionFormSections.inspectionForm",
    "inspectionFormSections.parent",
    "inspectionFormSections.subSections",
    "inspectionFormSections.subSections.inspectionFormSectionFields",
    "inspectionFormSections.subSections.inspectionFormSectionFields.copiedField",
    "notes",
    "reminders",
  ];

  return axios
    .get(`${url}/inspection-forms/${id}?include=${includes.join(",")}`, options)
    .then((response) => {
      let inspectionForm = jsona.deserialize(response.data);
      delete inspectionForm.links;
      return inspectionForm;
    });
}

function add(inspectionForm) {
  const payload = jsona.serialize({
    stuff: inspectionForm,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/inspection-forms`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(inspectionForm) {
  const payload = jsona.serialize({
    stuff: inspectionForm,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/inspection-forms/${inspectionForm.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/inspection-forms/${id}`, options);
}

function duplicate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/inspection-forms/${id}/duplicate`, options)
    .then((response) => {
      let inspectionForm = jsona.deserialize(response.data);
      delete inspectionForm.links;
      return inspectionForm;
    });
}

function syncFieldsDryRun({ file, id }) {
  const payload = { file: file };

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/inspection-forms/${id}/sync-fields/dry-run`, payload, options)
    .then((response) => {
      return response.data;
    });
}

function syncFieldsRun({ file, id }) {
  const payload = { file: file };

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/inspection-forms/${id}/sync-fields/run`, payload, options)
    .then((response) => {
      return response.data;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  duplicate,
  syncFieldsDryRun,
  syncFieldsRun,
};
