export default {
  CLASS: "Type de véhicule",

  VEHICLE_A: "Petite voiture",
  VEHICLE_C: "Voiture de taille moyenne",
  VEHICLE_E: "Voiture de grande taille",
  VEHICLE_F: "Voiture de luxe",
  VEHICLE_I: "Voiture sportive",
  VEHICLE_K: "Camionnette",
  VEHICLE_N: "Petit crossover/SUV",
  VEHICLE_P: "Grand crossover/SUV",
  VEHICLE_R: "Petit crossover/SUV de luxe",
  VEHICLE_T: "Grand crossover/SUV de luxe",
  VEHICLE_V: "Fourgonnette compacte",
  VEHICLE_W: "Minifourgonnette",
  VEHICLE_X: "Fourgonnette de grande taille",

  VEHICLE_LONG_A: "Voiture sous-compacte",
  VEHICLE_LONG_B: "Voiture compacte",
  VEHICLE_LONG_C: "Voiture de taille moyenne",
  VEHICLE_LONG_E: "Voiture de grande taille",
  VEHICLE_LONG_F: "Voiture de luxe intermédiaire",
  VEHICLE_LONG_G: "Voiture de luxe",
  VEHICLE_LONG_H: "Voiture de prestige",
  VEHICLE_LONG_I: "Voiture sportive",
  VEHICLE_LONG_J: "Voiture sportive premium",
  VEHICLE_LONG_K: "Petite camionnette",
  VEHICLE_LONG_M: "Camionnette de grande taille",
  VEHICLE_LONG_N: "Crossover sous-compact",
  VEHICLE_LONG_O: "Crossover/SUV compact",
  VEHICLE_LONG_P: "Crossover/SUV de taille moyenne",
  VEHICLE_LONG_Q: "Crossover/SUV de grande taille",
  VEHICLE_LONG_R: "Crossover/SUV de luxe sous-compact",
  VEHICLE_LONG_S: "Crossover/SUV de luxe compact",
  VEHICLE_LONG_T: "Crossover/SUV de luxe de taille moyenne",
  VEHICLE_LONG_U: "Crossover/SUV de luxe de grande taille",
  VEHICLE_LONG_V: "Fourgonnette compacte",
  VEHICLE_LONG_W: "Minifourgonnette",
  VEHICLE_LONG_X: "Fourgonnette de grande taille",
};
