import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function get(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/estimations/vehicle-price`, data, options)
    .then((response) => {
      let vehicle = response.data;
      return vehicle;
    });
}

export default {
  get,
};
