export default {
  AB: "Alberta",
  BC: "Colombie-Britannique",
  CN: "National",
  MB: "Manitoba",
  NB: "Nouveau-Brunswick",
  NL: "Terre-Neuve-et-Labrador",
  NS: "Nouvelle-Écosse",
  NT: "Territoires du Nord-Ouest",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Île-du-Prince-Édouard",
  QC: "Québec",
  SK: "Saskatchewan",
  YT: "Yukon",
};
