export default {
  RESELLER_PRODUCTS_LIST: "Liste des produits",
  ADD_RESELLER_PRODUCT: "Ajouter un produit",
  RESELLER_PRODUCT_ADDED: "Produit ajouté",
  EDIT_RESELLER_PRODUCT: "Modifier un produit",
  RESELLER_PRODUCT_UPDATED: "Produit mis a jour",
  DELETE_THIS_RESELLER_PRODUCT: "Supprimer ce produit?",
  RESELLER_PRODUCT_DELETED: "Produit supprimé",
  VIEW_RESELLER_PRODUCT: "Produit",
};
