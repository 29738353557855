import router from "@/router";

export const state = () => ({
  history: [],
  currentIndex: -1,
});

export const mutations = {
  PUSH_ROUTE(state, route) {

    if (route.query.tmp) {
      return;
    }

    const currentRoute = state.history[state.currentIndex];

    const getTabParam = (route) =>
      new URLSearchParams(route.fullPath.split("?")[1]).get("tab");
    const getOtherParams = (route) => {
      const params = new URLSearchParams(route.fullPath.split("?")[1]);
      params.delete("tab");
      return params.toString();
    };

    if (
      currentRoute &&
      currentRoute.path === route.path &&
      getOtherParams(currentRoute) === getOtherParams(route) &&
      getTabParam(currentRoute) !== getTabParam(route)
    ) {
      state.history[state.currentIndex] = route;
    } else {
      if (state.currentIndex < state.history.length - 1) {
        state.history = state.history.slice(0, state.currentIndex + 1);
      }
      state.history.push(route);
      if (state.history.length > 100) state.history.shift();
      else state.currentIndex++;
    }
  },
  GO_BACK(state) {
    if (state.currentIndex > 0) {
      state.currentIndex--;
    }
  },
  GO_FORWARD(state) {
    if (state.currentIndex < state.history.length - 1) {
      state.currentIndex = state.history.length + 1;
    }
  },
  CLEAR_HISTORY(state) {
    state.history = [];
    state.currentIndex = -1;
  },
  GO_TO_ROUTE_INDEX(state, index) {
    if (index >= 0 && index < state.history.length) {
      state.currentIndex = index;
    }
  },
};

export const actions = {
  pushRoute({ state, commit }, route) {
    commit("PUSH_ROUTE", route);
  },
  goBack({ state, commit }) {
    if (state.currentIndex > 0) {
      commit("GO_BACK");
      const previousRoute = state.history[state.currentIndex];

      // when the previous route is the same as the route we want to navigate to, 
      // vue does not refresh the route, so i must redirect to dashboard first and then navigate to the route
      // i add the tmp query to prevent the navigation to the dashboard to be recorded in the history
      router.push({ name: 'Dashboard', query: { tmp: true } }).then(() => {
        router.push(previousRoute);
      });
    }
  },
  goToPreviousPath({ state, commit }) {
    let newIndex = state.currentIndex - 1;
    const hasActionParam = (route) => {
      if (!route) {
        return false;
      }
      const params = new URLSearchParams(route.fullPath.split("?")[1]);
      return params.has("action");
    };

    while (
      newIndex >= 0 &&
      state.history[newIndex].path === state.history[state.currentIndex].path
      && hasActionParam(state.history[newIndex])
    ) {
      newIndex--;
    }
    if (newIndex >= 0) {
      commit("GO_TO_ROUTE_INDEX", newIndex);
      const previousRoute = state.history[newIndex];

      // when the previous route is the same as the route we want to navigate to, 
      // vue does not refresh the route, so i must redirect to dashboard first and then navigate to the route
      // i add the tmp query to prevent the navigation to the dashboard to be recorded in the history
      router.push({ name: 'Dashboard', query: { tmp: true } }).then(() => {
        router.push(previousRoute);
      });
    }
  },
  goBackToPreviousGrid({ state, commit }) {
    let newIndex = state.currentIndex - 1;
    const hasActionParam = (route) => {
      if (!route) {
        return false;
      }
      const params = new URLSearchParams(route.fullPath.split("?")[1]);
      return params.has("action");
    };
    while (newIndex >= 0 && hasActionParam(state.history[newIndex])) {
      newIndex--;
    }
    if (newIndex >= 0) {
      commit("GO_TO_ROUTE_INDEX", newIndex);
      const targetRoute = state.history[newIndex];

      // when the previous route is the same as the route we want to navigate to, 
      // vue does not refresh the route, so i must redirect to dashboard first and then navigate to the route
      // i add the tmp query to prevent the navigation to the dashboard to be recorded in the history
      router.push({ name: 'Dashboard', query: { tmp: true } }).then(() => {
        router.push(targetRoute);
      });
    }
  },
  goForward({ state, commit }) {
    if (state.currentIndex < state.history.length - 1) {
      commit("GO_FORWARD");
      const nextRoute = state.history[state.currentIndex];

      // when the previous route is the same as the route we want to navigate to, 
      // vue does not refresh the route, so i must redirect to dashboard first and then navigate to the route
      // i add the tmp query to prevent the navigation to the dashboard to be recorded in the history
      router.push({ name: 'Dashboard', query: { tmp: true } }).then(() => {
        router.push(nextRoute);
      });
    }
  },
  clearHistory({ commit }) {
    commit("CLEAR_HISTORY");
  },
};

export const getters = {
  history: (state) => state.history,
  currentRoute: (state) => state.history[state.currentIndex] || null,
  previousRoute: (state) => state.history[state.currentIndex] || null,
  previousPathRoute: (state) => {
    let newIndex = state.currentIndex - 1;
    const hasActionParam = (route) => {
      if (!route) {
        return false;
      }
      const params = new URLSearchParams(route.fullPath.split("?")[1]);
      return params.has("action");
    };
    while (
      newIndex >= 0 &&
      state.history[newIndex].path === state.history[state.currentIndex].path
      && hasActionParam(state.history[newIndex])
    ) {
      newIndex--;
    }
    if (newIndex >= 0) {
      return state.history[newIndex];
    }
    return null;
  },
  previousGridRoute: (state) => {
    let newIndex = state.currentIndex - 1;
    const hasActionParam = (route) => {
      if (!route) {
        return false;
      }
      const params = new URLSearchParams(route.fullPath.split("?")[1]);
      return params.has("action");
    };
    while (newIndex >= 0 && hasActionParam(state.history[newIndex])) {
      newIndex--;
    }
    if (newIndex >= 0) {
      const targetRoute = state.history[newIndex];
      return targetRoute;
    }
    return null;
  },
};

const routes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default routes;
