export default {
  MAKE_VEHICLE_ESTIMATION: "Faire une évaluation",
  ESTIMATE_VEHICLE: "Évaluation d'un véhicule",
  ESTIMATE_ESS: "Évaluation ESS",
  ESTIMATE_BLACK_BOOK_DRILLDOWN: "Évaluation Black Book Drilldown",
  ESTIMATE_BLACK_BOOK_RETAILS: "Liste des ventes en details",
  BLACK_BOOK: {
    DRILLDOWN: {
      RETAIL: "Estimation Black Book (Vente en details)",
      WHOLE: "Estimation Black Book (Vente en gros)",
    },
    RETAILS: "Moyen des ventes en details",
  },

  ESTIMATE_BLACK_BOOK_DRILLDOWN_RETAIL_VALUE: "Valeur au détail (Black book)",
  ESTIMATE_BLACK_BOOK_DRILLDOWN_WHOLESALE_VALUE:
    "Valeur au Wholesale (Black book)",
  ESTIMATE_ESS_RETAIL_AVG_VALUE: "Valeur Moyenne Ess Détail",
  ESTIMATE_ESS_AVG_VALUE: "Valeur Moyenne Ess",

  ESS: "Estimation ESS",

  ESS_OBJECT: {
    RETAIL: "Estimation Ess (Vente en details)",
    WHOLE: "Estimation Ess (Vente en gros)",
  },

  AVG_DAYS_SALE:
    "Nombre moyen de jour du marché : ({avg} jour) avant vente | Nombre moyen de jour du marché : ({avg} jour) avant vente | Nombre moyen de jour du marché : ({avg} jours) avant vente",

  AVG: {
    WHOLE: "Moyenne des ventes en gros",
    RETAIL: "Moyenne des ventes en details",
  },

  PERCENTS: {
    DRILLDOWN: {
      RETAIL: "Pourcentage de Black Book (Vente en details)",
      WHOLE: "Pourcentage de Black Book (Vente en gros)",
    },
    RETAILS: "Pourcentage de moyen des ventes en details",
    ESS: {
      RETAIL: "Pourcentage Ess (Vente en details)",
      WHOLE: "Pourcentage Ess (Vente en gros)",
    },
  },

  AGGRESSION_PERCENTAGE: "Pourcentage d'agressivité",
  CONCESSION_PROFIT: "Profit concession",
  DESIRED_PROFIT: "Profit Désiré",
  PRICE_VARIATION: "Variation du prix",

  OFFICIAL_PRICE: "Prix officiel",
  LOW_OFFICIAL_PRICE: "Prix officiel bas",
  HIGH_OFFICIAL_PRICE: "Prix officiel haut",

  OFFICIAL_TAXED_PRICE: "Prix officiel taxé",
  LOW_OFFICIAL_TAXED_PRICE: "Prix officiel taxé bas",
  HIGH_OFFICIAL_TAXED_PRICE: "Prix officiel taxé haut",

  ADD_TAXES: "Ajout des taxes",
  PRICE_RESULT: "Résultat des prix",

  RATIO_PRICE_KM: "Rapport prix/km",

  WHOLE: "Revente",
  RETAIL: "Detail",

  STATUS: "État",
  STATUS_XCLEAN: "Excellent",
  STATUS_CLEAN: "Bon",
  STATUS_AVG: "Moyen",
  STATUS_ROUGH: "Mauvais",

  PRICE: "Prix",
  BASE_PRICE: "Prix de base",
  MILEAGE_PRICE: "Prix du kilométrage",
  REGIONAL_PRICE: "Prix régional",
  ADJUSTED_PRICE: "prix ajusté",
  VEHICLE_KMS: "Kilométrage du véhicule",

  KMS: "Kilométrage",
  KMS_RATIO: "Kilométrage ratio",
  ESTIMATE: "Évaluation",
  ESTIMATES: "Evaluations",
  KMS_MIN: "Kilométrage min",
  KMS_MAX: "Kilométrage max",
  KMS_AVG: "Kilométrage moy",
  KMS_AVG_RATIO: "Kilométrage moy ratio",
  KMS_AVG_MIN: "Kilométrage moy min",
  KMS_AVG_MAX: "Kilométrage moy max",
  ESTIMATE_SOLD: "Vente en gros ESS",
  ESTIMATE_SOLD_ACTIVE: "Prix courant",
  ESTIMATE_SOLD_ESS: "Vente au détail Ess",
  PROVINCE: "Province",
  VIN: "Vin",
  MAKE: "Marque",
  MODEL: "Modèle",
  SERIES: "# série",
  MODEL_YEAR: "Année",
  STYLE: "Style",

  VEHICLE_ESTIMATE_BTN: "Évaluer",
  RETAILS: {
    PRICE_AVG: "Moyenne des prix",
    MILEAGE_AVG: "Kilométrage moyen",
    SOLD_PRICE_AVG: "Moyenne des prix des vendus",
    SOLD_MILEAGE_AVG: "Kilométrage moyen des vendus",
    ACTIVE_PRICE_AVG: "Moyenne des prix des actifs",
    ACTIVE_MILEAGE_AVG: "Kilométrage moyen des actifs",
    VALID_PRICE_AVG: "Moyenne des prix des valides",
    VALID_MILEAGE_AVG: "Kilométrage moyen des valides",
    VALID_RANGE: "Marge de prix valide (30%)",
    COMBINED_SCORE_AVG: "Moyenne des scores combiné",
  },
  VALID: "Prix dans la marge",
  STANDARDIZED_PRICE: "Prix normalisé",
  STANDARDIZED_MILEAGE: "Kilométrage normalisé",
  COMBINED_SCORE: "Score combiné",
  WEIGHTED_PERCENTAGE: "Pourcentage Pondéré",
  VRANK: "Rang",

  API: "Api d'évaluation de vehicle",
  IFRAME: "Iframe d'évaluation de vehicle",
  IFRAME_COPY_BTN: "Copier",
  IFRAME_COPIED_TITLE: "Copié!",
  IFRAME_COPIED_MESSAGE: "Le code HTML a bien été copié",

  FINAL_ESTIMATE: "Estimation finale",
  DELETE_THIS_REQUEST_LOG: "Supprimer cet appel api?",
  REQUEST_LOG_DELETED: "Appels api supprimé",
  REQUEST_LOGS_LIST: "Appels API",
  REQUEST_LOG: "Appel api",
  TOTAL_REQUEST_LOG:
    "Vous n'avez aucun appel api | Vous avez au total {total} appel api | Vous avez au total {total} appels api",

  MIN_KMS: "Kilométrage minimum",
  MAX_KMS: "Kilométrage maximum",
  MIN_PRICE: "Prix minimum",
  MAX_PRICE: "Prix maximum",

  DAYS_ON_MARKET_AVG: "Nombre de jours moyen sur le marché",
  SOLD_DAYS_ON_MARKET_AVG: "Nombre de jours moyen des vendus sur le marché",
  ACTIVE_DAYS_ON_MARKET_AVG: "Nombre de jours moyen des actifs sur le marché",

  SOLDS: "Vendus",
  ACTIVES: "Actifs",
  PRICES: "Prix",
  MILEAGES: "Kilométrages",

  ALL_VEHICLES: "Tous les véhicules",
  ACTIVE_VEHICLES: "Véhicules Actifs",
  SOLD_VEHICLES: "Véhicules Vendus",
  PRICE_AVG: "Moyenne des prix",
  KMS_AVG: "Moyenne des kms",
  DAY_AVG: "Jour moyen",

  IS_ACCIDENT_VEHICLE: "Véhicule accidenté?",
  REPAIRS_COST: "Valeur des dommages",
  IS_TOTAL_LOSS: "Reconstruit?",

  VEHICLE_COUNT: "Nombre de véhicule",
  MEMBER_SHIP: "Appartenance",

  MSRP: "msrp",
  WARRANTY: {
    BASIC: "Garantie basique",
    POWERTRAIN: "Garantie du groupe motopropulseur",
  },
};
