const portalBaseUrl =
  process.env.VUE_APP_PORTAL_BASE_URL ?? "https://dev.smaaart.ca";

const portalUrl = {
  install: (Vue) => {
    Vue.prototype.$portalUrl = (value = "") => `${portalBaseUrl}/${value}`;
  },
};

export default portalUrl;
