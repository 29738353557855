import { QUERY_ACTIONS_VIEW } from "../constants/common";
import store from "../store";

const appNavigation = {
  install: (Vue) => {
    Vue.prototype.$goBack = () => {
      store.dispatch("routes/goBack");
    };
    Vue.prototype.$goForward = () => {
      store.dispatch("routes/goForward");
    };
  },
};

export default appNavigation;
