export default {
  PURCHASES_PAYMENTS_LIST: "Liste des paiements",
  ADD_PURCHASES_PAYMENT: "Ajouter un paiement",
  PURCHASES_PAYMENT_ADDED: "Paiement ajouté",
  AUTHORIZATIONS: "Autorisations",
  EDIT_PURCHASES_PAYMENT: "Modifier le paiement",
  PURCHASES_PAYMENT_SOURCE_MANUAL: "Manuel",
  PURCHASES_PAYMENT_SOURCE_PAYPAL: "PayPal",
  PURCHASES_PAYMENT_SOURCE_STRIPE: "Stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Id transaction",
  TRANSACTION_DATA: "Données transaction",
  SOURCE_DETAILS: "Détails du paiement",
  VIEW_INVOICE: "Voir la facture",
  VIEW_PURCHASES_PAYMENT: "Paiement achat",
};
