<template>
  <div v-if="organization">
    <object-link
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      :object="organization"
    />
    <span v-else>{{ $objectDenomination(organization) }}</span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "organization",

  components: {},

  mixins: [formMixin],

  props: {
    organization: {
      type: Object,
      default: () => ({}),
      description: "Organization",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    organization(organization) {},
  },
};
</script>
