import service from "@/store/services/inspection-form-section-fields-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  inspectionFormSectionField: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, inspectionFormSectionField) => {
    state.inspectionFormSectionField = inspectionFormSectionField;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service
      .get(params, this.$axios)
      .then((inspectionFormSectionField) => {
        commit("SET_RESOURCE", inspectionFormSectionField);
      });
  },

  add({ commit, dispatch }, params) {
    return service
      .add(params, this.$axios)
      .then((inspectionFormSectionField) => {
        commit("SET_RESOURCE", inspectionFormSectionField);
      });
  },

  update({ commit, dispatch }, params) {
    return service
      .update(params, this.$axios)
      .then((inspectionFormSectionField) => {
        commit("SET_RESOURCE", inspectionFormSectionField);
      });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  inspectionFormSectionField: (state) => state.inspectionFormSectionField,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((inspectionFormSectionField) => ({
      id: inspectionFormSectionField.id,
      name: inspectionFormSectionField.name,
    }));
  },
};

const inspectionForms = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default inspectionForms;
