import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/requests`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/requests/${id}?include=organization,creator,assignedTo,customer,responseObject,allowedLocations,tags,approvedBy,assignedBy,completedBy,vehicle`,
      options
    )
    .then((response) => {
      let request = jsona.deserialize(response.data);
      delete request.links;
      return request;
    });
}

function add(request) {
  const payload = jsona.serialize({
    stuff: request,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/requests`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(request) {
  const payload = jsona.serialize({
    stuff: request,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/requests/${request.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/requests/${id}`, options);
}

function assign(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/requests/${id}/assign?include=organization,creator,assignedTo,customer,responseObject,allowedLocations,tags,approvedBy,assignedBy,completedBy,vehicle`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function approve(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/requests/${id}/approve?include=organization,creator,assignedTo,customer,responseObject,allowedLocations,tags,approvedBy,assignedBy,completedBy,vehicle`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function complete(id, data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(
      `${url}/requests/${id}/complete?include=organization,creator,assignedTo,customer,responseObject,allowedLocations,tags,approvedBy,assignedBy,completedBy,vehicle`,
      data,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function cancel(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/requests/${id}/cancel?include=organization,creator,assignedTo,customer,responseObject,allowedLocations,tags,approvedBy,assignedBy,completedBy,vehicle`,
      options
    )
    .then((response) => {
      let request = jsona.deserialize(response.data);
      delete request.links;
      return request;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  assign,
  complete,
  cancel,
  approve,
};
